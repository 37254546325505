import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { useTasterApis } from '@/api/TasterApiContext';
import { useOnSiteInfo } from '@/hooks/useOnSiteInfo';

import { paymentsKeys } from './payments.helpers';
import { GetLastPaymentErrorRequest, PaymentFailureInformation } from './types';

export const lastPaymentErrorQueryOptions = {
  queryKey: (requestParams: GetLastPaymentErrorRequest) =>
    [...paymentsKeys.all, 'lastPaymentError', requestParams] as const,
};

export interface UseLastPaymentErrorQueryProps<TData> {
  options?: Omit<
    UseQueryOptions<PaymentFailureInformation, Error, TData, ReturnType<typeof lastPaymentErrorQueryOptions.queryKey>>,
    'queryKey' | 'queryFn'
  >;
  requestParams: GetLastPaymentErrorRequest;
}

export const useLastPaymentErrorQuery = <TData = PaymentFailureInformation>({
  options,
  requestParams,
}: UseLastPaymentErrorQueryProps<TData>) => {
  const { authenticationPassPhrase } = useOnSiteInfo();
  const {
    gatewayClickCollect: { paymentsApi },
  } = useTasterApis();

  return useQuery({
    ...options,
    queryFn: () =>
      paymentsApi.getLastPaymentError(requestParams, async ({ init }) => ({
        ...init,
        headers: {
          ...init.headers,
          Authorization: `Basic ${window.btoa(authenticationPassPhrase)}`,
        },
      })),
    queryKey: lastPaymentErrorQueryOptions.queryKey(requestParams),
  });
};
