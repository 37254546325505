import { CardProps } from '@chakra-ui/card/dist/card';
import { MoonIcon } from '@chakra-ui/icons';
import { Box, Card, Heading, Icon, Image, Text, useBoolean, VStack } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useNavigate } from 'react-router-dom';

import { RestaurantDeliveryPlatformInformation } from '@/api/gateway-click-collect/locations';
import { OnlineStatus } from '@/api/gateway-click-collect/restaurants';
import { PlatformsDeliveryModal } from '@/components/PlatformsDeliveryModal';
import { OpeningHoursLabel } from '@/components/RestaurantCard/OpeningHoursLabel';
import { OrderButton } from '@/components/RestaurantCard/OrderButton';
import { sortPictureClassificationsByWidth } from '@/helpers/menu.helpers';
import { RestaurantWithConcept } from '@/types';

import { formatOpeningHours } from './RestaurantCard.helpers';
import { RestaurantCardBody } from './RestaurantCardBody';
import { RestaurantCardOverlay } from './RestaurantCardOverlay';

type RestaurantCardProps = CardProps & {
  compact?: boolean;
  isDelivery?: boolean;
  locationUuid?: string;
  restaurantPlatforms?: Array<RestaurantDeliveryPlatformInformation>;
  restaurantWithConcept: RestaurantWithConcept;
  onRestaurantClick: (restaurantWithConcept: RestaurantWithConcept) => void;
};

export const RestaurantCard = ({
  compact: compactProp = false,
  isDelivery = false,
  restaurantPlatforms,
  restaurantWithConcept,
  onRestaurantClick,
  ...cardProps
}: RestaurantCardProps) => {
  const navigate = useNavigate();

  const { concept, ...restaurant } = restaurantWithConcept;
  const { kitchenLabel, restaurantPlatformId, restaurantLabel, openingHours = { weekdays: {} } } = restaurant;

  const [isDeliveryOpen, setIsDeliveryOpen] = useBoolean(false);

  const {
    isOpenNow: isOpen,
    isOpenToday,
    openingLabel,
  } = formatOpeningHours({
    restaurantOpeningHours: openingHours,
    status: restaurantWithConcept?.status ?? OnlineStatus.Close,
  });

  const compact = !!isDelivery || compactProp;

  const handleClick = () => {
    if (isDelivery) {
      setIsDeliveryOpen.on();
    } else {
      onRestaurantClick(restaurantWithConcept);
      navigate(`/menu/${restaurantPlatformId}`);
    }
  };

  const conceptHeroPicture = sortPictureClassificationsByWidth(concept.heroPictures)[0];

  return (
    <Card
      as="article"
      bg="white"
      border="1px solid #EAECF0"
      borderRadius="lg"
      boxShadow="0px 0px 2px rgba(55, 19, 23, 0.08)"
      disabled={!isOpen}
      display="flex"
      flexDirection="column"
      onClick={isOpen ? handleClick : undefined}
      overflow="hidden"
      height="100%"
      {...cardProps}
    >
      <Box height="150px" pos="relative" width="100%">
        <Image objectFit="cover" height="100%" width="100%" src={conceptHeroPicture.url} alt={restaurantLabel} />
        <RestaurantCardOverlay isOpen={isOpen}>
          {compact && (
            <OpeningHoursLabel isOpenNow={isOpen} openingLabel={openingLabel} left={2} pos="absolute" top={1} />
          )}
          {!isOpen && (
            <>
              <Icon as={MoonIcon} boxSize={6} />
              <Text fontSize="md" fontWeight={500}>
                <Trans>Closed</Trans>
              </Text>
            </>
          )}
        </RestaurantCardOverlay>
      </Box>
      <RestaurantCardBody compact={compact}>
        <VStack align="stretch" flex={compact ? 0 : '9999 0 150px'}>
          <Heading as="h3" fontWeight="700" fontSize="md">
            {restaurantLabel}
          </Heading>
          {!compact && <OpeningHoursLabel isOpenNow={isOpen} openingLabel={openingLabel} />}
        </VStack>
        <OrderButton
          flex={compact ? '0 0 auto' : 1}
          hasPlatforms={!!restaurantPlatforms}
          isDelivery={isDelivery}
          isOpen={isOpen}
          isOpenToday={isOpenToday}
          minWidth="fit-content"
          isDisabled={!(isOpen || isOpenToday)}
          onClick={handleClick}
        />
        {isDelivery && !!restaurantPlatforms && (
          <PlatformsDeliveryModal
            isOpen={isDeliveryOpen}
            kitchenLabel={kitchenLabel}
            onClose={setIsDeliveryOpen.off}
            restaurantPlatforms={restaurantPlatforms}
          />
        )}
      </RestaurantCardBody>
    </Card>
  );
};
