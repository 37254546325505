import { Alert, AlertDescription, AlertTitle, Icon, Stack, Text } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { useEffect } from 'react';
import { MdErrorOutline } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';

import { OrderStatusEnum, ReadiedOrderSummary, useOrderQuery } from '@/api/gateway-click-collect/orders';
import { useLastPaymentErrorQuery } from '@/api/gateway-click-collect/payments';
import terminalAnimation from '@/assets/anim/lottie_terminal.json';
import { LottieAnimation } from '@/components/LottieAnimation';
import { useTerminalContext } from '@/contexts';
import { useCart } from '@/contexts/cart';
import dataLayer from '@/helpers/dataLayer.helpers';

import { PairDeviceLayout } from '../PairDeviceLayout';
import { CancelOrderButton } from './CancelOrderButton';
import { CancelPaymentButton } from './CancelPaymentButton';

const isOrderValid = (order: ReadiedOrderSummary) => {
  const validOrderStatus: OrderStatusEnum[] = [
    OrderStatusEnum.Accepted,
    OrderStatusEnum.Created,
    OrderStatusEnum.Delivered,
    OrderStatusEnum.PaymentSucceeded,
    OrderStatusEnum.ReadyForDispatch,
  ];

  return validOrderStatus.includes(order.status);
};

const paymentErrorMessage: { [key: string]: JSX.Element } = {
  card_not_supported: (
    <Trans>This card does not support this kind of transaction. Please, try again with another card.</Trans>
  ),
  card_velocity_exceeded: (
    <Trans>
      The balance, credit limit, or transaction amount limit available on your card has been exceeded. Please contact
      your card issuer for more information, or try with another card.
    </Trans>
  ),
  customer_cancelled: <Trans>The transaction was cancelled on the card reader. You can place a new order.</Trans>,
  do_not_honor: (
    <Trans>
      The card was declined for an unknown reason. Please contact your card issuer for more information, or try with
      another card.
    </Trans>
  ),
  incorrect_number: <Trans>The card number is incorrect. Please try again using the correct card number.</Trans>,
  insufficient_funds: (
    <Trans>
      The card has insufficient funds to complete the purchase. Please try again with an alternative payment method.
    </Trans>
  ),
  invalid_amount: (
    <Trans>
      The payment amount is invalid, or exceeds the amount that&apos;s allowed. Please contact your card issuer to check
      that the card is working correctly, or try with another card.
    </Trans>
  ),
  invalid_pin: <Trans>The PIN entered is incorrect. Please try again using the correct PIN.</Trans>,
  lost_card: (
    <Trans>
      The payment was declined because the card is reported lost. Please contact your card issuer or use a different
      card.
    </Trans>
  ),
  offline_pin_required: (
    <Trans>
      The card was declined because it requires a PIN. Please try again by inserting your card and entering a PIN.
    </Trans>
  ),
  online_or_offline_pin_required: (
    <Trans>
      The card was declined because it requires a PIN. Please try again by inserting your card and entering a PIN.
    </Trans>
  ),
  parsing_error_default: (
    <Trans>
      The card was declined for an unknown reason. Please, contact your card issuer for more information, or try with
      another card.
    </Trans>
  ),
  previously_declined_do_not_retry: (
    <Trans>
      This transaction was previously declined. Please contact your card issuer for more information, or try with
      another card.
    </Trans>
  ),
  processing_error: (
    <Trans>
      An error occurred while processing the card. Please try again, or try with another card if you still have an
      error.
    </Trans>
  ),
  rate_limit_exceeded: (
    <Trans>
      The balance, credit limit, or transaction amount limit available on your card has been exceeded. Please, contact
      your card issuer for more information, or try with another card.
    </Trans>
  ),
  transaction_not_allowed: (
    <Trans>
      The card was declined for an unknown reason. Please contact your card issuer for more information, or try with
      another card.
    </Trans>
  ),
};

const getPaymentErrorMessage = (failureCode?: string): JSX.Element => {
  const defaultMessage = (
    <Trans>
      The card was declined for an unknown reason. Please, contact your card issuer for more information, or try with
      another card.
    </Trans>
  );

  return paymentErrorMessage[failureCode ?? ''] ?? defaultMessage;
};

export const PaymentInstructionsPage = () => {
  const navigate = useNavigate();
  const { restaurantPlatformId, orderUuid } = useParams() as { restaurantPlatformId: string; orderUuid: string };
  const { terminal, userLocation } = useTerminalContext();
  const { terminalUuid, locationUuid } = terminal.terminalInfo;
  const { cart } = useCart();

  const { data: order } = useOrderQuery({
    requestParams: {
      orderUuid,
    },
    options: {
      refetchInterval: (_data, context) => {
        if (context.state.fetchFailureCount > 3) {
          return false;
        }

        return 1000;
      },
    },
  });

  const paymentUuid = order?.paymentUuid ?? '';
  const isPaymentFailed = order?.status === OrderStatusEnum.PaymentFailed;

  const { data: lastPaymentError } = useLastPaymentErrorQuery({
    requestParams: {
      paymentUuid,
    },
    options: {
      enabled: !!paymentUuid && isPaymentFailed,
      refetchInterval: (_data, context) => {
        if (context.state.fetchFailureCount > 3 || context.state.status === 'success') {
          return false;
        }

        return 1000;
      },
    },
  });

  useEffect(() => {
    if (order && isOrderValid(order)) {
      dataLayer.logPaymentSuccess({
        kitchenLabel: userLocation.kitchenLabel,
        order,
        orderChannel: 'onsite',
      });
      navigate(`/onSite/orderConfirmed/${order.uuid}`);
    }
  }, [cart, navigate, order, userLocation]);

  return (
    <PairDeviceLayout headerTitle={t`Checkout`}>
      <Stack px={2} alignItems="center" position="relative" width="100%" height="100%">
        <CancelPaymentButton
          locationUuid={locationUuid}
          order={order}
          restaurantPlatformId={restaurantPlatformId}
          terminalUuid={terminalUuid}
          sx={{
            left: '16px',
            position: 'absolute',
            top: 0,
            transform: 'translateY(-50%)',
          }}
        />
        <Stack gap={12} alignItems="center">
          <LottieAnimation animation={terminalAnimation} sx={{ width: '250px' }} />
          <Stack gap={8} alignItems="center">
            <Text fontWeight={700} fontSize={32} maxWidth={400} textAlign="center">
              <Trans>Follow the instructions on the card machine </Trans>
            </Text>
            {isPaymentFailed && lastPaymentError && (
              <Alert
                background="red.error.25"
                border="1px solid"
                borderColor="red.error.300"
                borderRadius={12}
                padding={4}
                status="error"
                variant="subtle"
              >
                <Icon as={MdErrorOutline} color="red.error.600" width={8} height={8} mr={3} />
                <Stack gap={2}>
                  <AlertTitle color="red.error.700" fontSize="xl">
                    <Trans>Payment failed</Trans>
                  </AlertTitle>
                  <AlertDescription color="red.error.700" fontSize="lg">
                    {getPaymentErrorMessage(lastPaymentError.failureCode)}
                  </AlertDescription>
                </Stack>
              </Alert>
            )}
          </Stack>
          <CancelOrderButton
            locationUuid={locationUuid}
            order={order}
            terminalUuid={terminalUuid}
            sx={{
              background: 'white',
              border: '1px solid',
              borderColor: 'gray.300',
              color: 'gray.700',
            }}
          />
        </Stack>
      </Stack>
    </PairDeviceLayout>
  );
};
