import { HStack, Image, Text } from '@chakra-ui/react';

import nf525Logo from '@/assets/img/logo-nf-525.png';

export const NF525Badge = () => {
  return (
    <HStack alignItems="center" gap={4}>
      <Image boxSize="90px" src={nf525Logo} alt="NF 525 logo" />
      <div>
        <Text>Certification NF525</Text>
        <Text color="gray.600" fontSize="sm">
          Feedme, version 3
        </Text>
        <Text color="gray.600" fontSize="sm">
          Certificat n°525/0576-2
        </Text>
        <Text color="gray.600" fontSize="sm">
          Catégorie B
        </Text>
      </div>
    </HStack>
  );
};
